import React, { useState, useEffect, useContext, createContext } from "react"
import { useDispatch } from "react-redux"

import { getFromQueryString, isBrowser, isMobile, getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem, callThunk } from "./helper"
import { handleEventError, logUser } from "./error"
import { resetStore } from "../appstate/reset"
import { fetchPermissions } from "../appstate/permissions"

import { useFirebase } from "./firebase"
import { useAnalytics } from "./analytics"

import * as CONSTANTS from "./constant"

const SIGN_IN_WITH_REDIRECT_CACHE_KEY = `signInWithRedirect`
const authContext = createContext()


// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const dispatch = useDispatch()
  const firebase = useFirebase()
  const analytics = useAnalytics()

  useEffect(() => {
    if(firebase) {
      const initializeAuth = async () => {
        try {
          // Subscribe to authStateChange
          const unsubscribe = firebase.auth().onAuthStateChanged(newUser => {
            try {
              if(newUser) {
                setUser(newUser)
                setLocalStorageItem(CONSTANTS.USER_STATUS_CACHE_KEY, CONSTANTS.USER_STATUS_LOGGED_IN)
                analytics.setUserId(newUser.uid)
                logUser(newUser)
                callThunk(dispatch, fetchPermissions, [newUser])
              } else {
                setUser(null)
                logUser(null)
                removeLocalStorageItem(CONSTANTS.USER_STATUS_CACHE_KEY)
                dispatch(resetStore())
              }
            } catch (err) {
              handleEventError(err, newUser, false)
              throw err
            }
          })
  
          //get result if signInWithRedirect
          if(getLocalStorageItem(SIGN_IN_WITH_REDIRECT_CACHE_KEY)){
            await firebase.auth().getRedirectResult()
            removeLocalStorageItem(SIGN_IN_WITH_REDIRECT_CACHE_KEY)
          }
  
          // Subscription unsubscribe function
          return () => unsubscribe()
        } catch (err) {
          handleEventError(err, false, false)
          throw err
        }
      }
      initializeAuth() 
    }
  },[firebase, analytics, dispatch])

  const signin = async (email, password) => {
    try {
      if(!isBrowser()) {
        return false
      }
  
      const response = await firebase.auth().signInWithEmailAndPassword(email, password)
      return response.user
    } catch (err) {
      handleEventError(err, user, false)
      throw err
    }
  }

  const signinWithGoogle = async () => {
    try {
      if(!isBrowser()) {
        return false
      }
      
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.addScope('profile');
      provider.addScope('email');
  
      if(isMobile()) {
        setLocalStorageItem(SIGN_IN_WITH_REDIRECT_CACHE_KEY,'true')
        await firebase.auth().signInWithRedirect(provider)
        return null
      }
      else {
        const response = await firebase.auth().signInWithPopup(provider)
        return response.user
      }
    } catch (err) {
      handleEventError(err, user, false)
      throw err
    }
  }

  const signup = async (email, password) => {
    try {
      if(!isBrowser()) {
        return false
      }
  
      const response = firebase.auth().createUserWithEmailAndPassword(email, password)
      return response.user
    } catch (err) {
      handleEventError(err, user, false)
      throw err
    }
  }

  const signout = async () => {
    try {
      if(!isBrowser()) {
        return false
      }
  
      return await firebase.auth().signOut()
    } catch (err) {
      handleEventError(err, user, false)
      throw err
    }
  }

  const sendPasswordResetEmail = async (email) => {
    try {
      if(!isBrowser()) {
        return false
      }
  
      await firebase.auth().sendPasswordResetEmail(email)
      return true
    } catch (err) {
      handleEventError(err, user, false)
      throw err
    }
  }

  const confirmPasswordReset = async (password, code) => {
    try {
      if(!isBrowser()) {
        return false
      }
  
      // Get code from query string object
      const resetCode = code || getFromQueryString("oobCode")
  
      await firebase.auth().confirmPasswordReset(resetCode, password)
      return true
    } catch (err) {
      handleEventError(err, user, false)
      throw err
    }
  }

  const getUser = () => isBrowser() ? user : {} 

  const isLoggedIn = () => (getUser() && getUser().uid) ? true : false

  const auth = {
    getUser,
    isLoggedIn, 
    signin,
    signinWithGoogle, 
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  }
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... update when it changes.
export const useAuth = () => {
  return useContext(authContext)
}