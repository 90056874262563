import React, { useState, useEffect, useContext, createContext } from "react"

import { handleEventError } from "./error"

const firebaseContext = createContext()

export function FirebaseProvider({ children }) {
    const [firebase, setFirebase] = useState(null)

    useEffect(() => {
        if(!firebase) {
            const initializeFirebase = async () => {
                try {
                    // without dynamic import, firebase causes IndexDB issue during SSR
                    // https://github.com/firebase/firebase-js-sdk/issues/1797#issuecomment-498474850

                    const firebaseApp = import("firebase/app")
                    const firebaseAnalytics = import("firebase/analytics")
                    const firebaseAuth = import("firebase/auth")

                    const instances = await Promise.all([
                        firebaseApp,
                        firebaseAnalytics,
                        firebaseAuth,
                    ])

                    const firebaseInstance = instances[0]
                    firebaseInstance.initializeApp({
                        apiKey: process.env.GATSBY_FIREBASE_API_KEY, 
                        authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN, 
                        projectId: process.env.GATSBY_FIREBASE_PROJECT_ID, 
                        appId: process.env.GATSBY_FIREBASE_APP_ID, 
                        measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
                    })
                    firebaseInstance.analytics()

                    setFirebase(firebaseInstance)

                } catch (err) {
                    handleEventError(err, false, false)
                    throw err
                }
            }
            initializeFirebase() 
        }
    },[firebase])

    return <firebaseContext.Provider value={firebase}>{children}</firebaseContext.Provider>
}

// Hook for child components to get the firebase object ...
// ... update when it changes.
export const useFirebase = () => {
    return useContext(firebaseContext)
}
