import axios from "axios"
import { handleEventError } from "./error"

const api = axios.create({
    baseURL: process.env.GATSBY_API_BASE_URL,
    responseType: 'json'
})

export const callApi = async (user, requestConfig) => {
    try {
        const token = await user.getIdToken()
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`
        return await api.request(requestConfig)    
    } catch (err) {
        handleEventError(err, user, false)
        throw err
    }
}

export const callUnauthenticatedApi = async (requestConfig) =>  {
    try {
        return await api.request(requestConfig)
    } catch (err) {
        handleEventError(err, false, false)
        throw err
    }
}