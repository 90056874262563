import { createSlice } from "@reduxjs/toolkit"

import { callApi } from "../services/api"
import { callThunk } from "../services/helper"
import { handlePromiseError } from "../services/error"
import * as CONSTANTS from "../services/constant"

const NEW_FORM_SEQUENCE_SUFFIX = 'default'

const websites = createSlice({
    name: 'websites',
    initialState: [],
    reducers: {
        addWebsite(state, action) {
            state.unshift(action.payload)
            return state
        },
        updateWebsite(state, action) {
            const index = state.findIndex(website => website.submissionId === action.payload.submissionId)
            if(index !== -1) {
                state[index] = action.payload
            }
            return state
        },
        removeWebsite(state, action) {
            return state.filter(website => website.submissionId !== action.payload)
        },
        setWebsites(state, action){
            return action.payload
        }
    }
})

export const {
    addWebsite,
    updateWebsite,
    removeWebsite,
    setWebsites
} = websites.actions

export default websites.reducer

// thunk actions
export const fetchWebsites = (user, onSuccess, onError) => async dispatch => {
    try {
        const response = await callApi(user, {
            method: 'get',
            url:`/records/${process.env.GATSBY_WEBSITES_COLLECTION_ID}`

        })
        dispatch(setWebsites(response.data.result))
        if(onSuccess){
            onSuccess(response)
        }

    } catch (err) {
        handlePromiseError(err, onError)
    }
}

export const createWebsite = (user, website, onSuccess, onError) => async dispatch => {
    try {
        const { domain, platform, stage, version, life, plan, modules, sequences, forms } = website
        const response = await callApi(user, {
            method: 'post',
            url:'/records',
            params: {
                sequenceId: process.env.GATSBY_WRITE_FILE_SEQUENCE_ID
            },
            data: {
                collectionId: process.env.GATSBY_WEBSITES_COLLECTION_ID,
                submissionData: website,
                contentType: CONSTANTS.CONTENT_TYPE_JSON,
                maxAge:CONSTANTS.CONFIG_FILE_MAX_AGE,
                fileKeyTemplate:{
                    placeholders:[{
                        regex:"%%filename%%",
                        key:"submissionId"
                    }],
                    payload:"%%filename%%"
                },
                fileKeySuffix: "/config.json",
                fileDataTemplate:{
                    placeholders:[{
                        regex:"%%websiteId%%",
                        key:"submissionId"
                    }],
                    payload: {
                        "id":"%%websiteId%%",
                        "domain": domain,
                        "version":version,
                        "platform": platform,
                        "stage": stage,
                        "expires":0,
                        "life":life,
                        "plan":plan,
                        "modules":modules,
                        "sequences":sequences,
                        "forms": forms
                    }
                }
            }
        })
        dispatch(addWebsite({ submissionId: response.data.result[0].submissionId, submissionData: website }))
        if(onSuccess) {
            onSuccess(response)
        }

    } catch (err) {
        handlePromiseError(err, onError)
    }
    
    
}

export const deleteWebsite = (user, websiteId, onSuccess, onError) => async dispatch => {
    try {
        const response = await callApi(user, {
            method: 'delete',
            url:`/records/${process.env.GATSBY_WEBSITES_COLLECTION_ID}`,
            data: {
                submissionId: websiteId,
                softDelete: true
            }
        })
        dispatch(removeWebsite(websiteId))
        if(onSuccess) {
            onSuccess(response)
        }

    } catch (err) {
        handlePromiseError(err, onError)
    }
}

export const addForm = (user, websiteId, form, onSuccess, onError) => async (dispatch, getState) => {
    try {
        const currentWebsite = await getCurrentWebsite(user, websiteId, dispatch, getState)
        const { domain, platform, stage, life, plan, modules, sequences, forms } = currentWebsite.submissionData
        
        //create a default sequence for form
        const formSequence = {
            actions: [{
                type: form.provider,
                portalId: form.portalId,
                formId: form.formId
            }]
        }

        //merge new form and sequence with existing forms and sequences on website
        const newSequences = {...sequences}
        newSequences[`${form.formKey}-${NEW_FORM_SEQUENCE_SUFFIX}`] = formSequence
        const newForms = {...forms}
        newForms[form.formKey]  = {
            ...form,
            sequenceKeys: [`${form.formKey}-${NEW_FORM_SEQUENCE_SUFFIX}`] 
        }
        
        //enable forms module if not enabled
        const newModules = {...modules, forms: true}

        //update website version timestamp
        const newVersion = (new Date()).getTime()

        const newSubmissionData = {
            ...currentWebsite.submissionData,
            forms: newForms,
            sequences: newSequences,
            modules: newModules,
            version: newVersion, 
        }

        const response = await callApi(user, {
            method: 'patch',
            url:`/records/${process.env.GATSBY_WEBSITES_COLLECTION_ID}`,
            params: {
                sequenceId: process.env.GATSBY_WRITE_FILE_SEQUENCE_ID
            },
            data: {
                submissionId: websiteId,
                submissionData: newSubmissionData,
                contentType: CONSTANTS.CONTENT_TYPE_JSON,
                maxAge:CONSTANTS.CONFIG_FILE_MAX_AGE,
                fileKeyTemplate:{
                    placeholders:[{
                        regex:"%%filename%%",
                        key:"submissionId"
                    }],
                    payload:"%%filename%%"
                },
                fileKeySuffix: "/config.json",
                fileDataTemplate:{
                    placeholders:[{
                        regex:"%%websiteId%%",
                        key:"submissionId"
                    }],
                    payload: {
                        "id":"%%websiteId%%",
                        "domain": domain,
                        "version": newVersion,
                        "platform": platform,
                        "stage": stage,
                        "expires": 0,
                        "life": life,
                        "plan": plan,
                        "modules": newModules,
                        "sequences": newSequences, 
                        "forms": newForms
                    }
                }
            }
        })
        dispatch(updateWebsite({...currentWebsite, submissionData: newSubmissionData}))
        if(onSuccess) {
            onSuccess(response)
        }

    } catch (err) {
        handlePromiseError(err, onError)
    }
}

export const deleteForm = (user, websiteId, formKey, onSuccess, onError) => async (dispatch, getState) => {
    try {
        const currentWebsite = await getCurrentWebsite(user, websiteId, dispatch, getState)
        const { domain, platform, stage, life, plan, modules, sequences, forms } = currentWebsite.submissionData
        const currentForm = forms[formKey]
    
        //remove form and default sequence from existing forms and sequences on website
        let newSequences = {...sequences}
        newSequences[currentForm.sequenceKeys[0]] = undefined
        
        let newForms = {...forms}
        newForms[formKey]  = undefined
        
        //disable forms module if all forms have been deleted
        const keepEnabled = Object.values(newForms).some(form => form !== undefined)
        const newModules = {...modules, forms: keepEnabled}
    
        if(!keepEnabled){
            newForms = {}
            if(!Object.values(newSequences).some(sequence => sequence !== undefined)) {
                newSequences = {}
            }
        }
    
        //update website version timestamp
        const newVersion = (new Date()).getTime()
    
        const newSubmissionData = {
            ...currentWebsite.submissionData,
            forms: newForms,
            sequences: newSequences,
            modules: newModules,
            version: newVersion, 
        }
    
        const response = await callApi(user, {
            method: 'patch',
            url:`/records/${process.env.GATSBY_WEBSITES_COLLECTION_ID}`,
            params: {
                sequenceId: process.env.GATSBY_WRITE_FILE_SEQUENCE_ID
            },
            data: {
                submissionId: websiteId,
                submissionData: newSubmissionData,
                contentType: CONSTANTS.CONTENT_TYPE_JSON,
                maxAge:CONSTANTS.CONFIG_FILE_MAX_AGE,
                fileKeyTemplate:{
                    placeholders:[{
                        regex:"%%filename%%",
                        key:"submissionId"
                    }],
                    payload:"%%filename%%"
                },
                fileKeySuffix: "/config.json",
                fileDataTemplate:{
                    placeholders:[{
                        regex:"%%websiteId%%",
                        key:"submissionId"
                    }],
                    payload: {
                        "id":"%%websiteId%%",
                        "domain": domain,
                        "version": newVersion,
                        "platform": platform,
                        "stage": stage,
                        "expires": 0,
                        "life": life,
                        "plan": plan,
                        "modules": newModules,
                        "sequences": newSequences, 
                        "forms": newForms
                    }
                }
            }
        })
        dispatch(updateWebsite({...currentWebsite, submissionData: newSubmissionData}))
        if(onSuccess) {
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }

}

export const updateWebsiteInstallStatus = (user, websiteId, installStatus, newOgImageUrl, onSuccess, onError) => async (dispatch, getState) => {
    try {
        const currentWebsite = await getCurrentWebsite(user, websiteId, dispatch, getState)
        const { ogImageUrl } = currentWebsite.submissionData

        const newSubmissionData = {
            ...currentWebsite.submissionData,
            installStatus: installStatus, 
            ogImageUrl: newOgImageUrl || ogImageUrl
        }

        const response = await callApi(user, {
            method: 'patch',
            url:`/records/${process.env.GATSBY_WEBSITES_COLLECTION_ID}`,
            data: {
                submissionId: websiteId,
                submissionData: newSubmissionData
            }
        })

        dispatch(updateWebsite({...currentWebsite, submissionData: newSubmissionData}))
        if(onSuccess) {
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }
}

export const updateFormInstallStatus = (user, websiteId, formKey, installStatus, onSuccess, onError) => async (dispatch, getState) => {
    try {
        const currentWebsite = await getCurrentWebsite(user, websiteId, dispatch, getState)
        const { forms } = currentWebsite.submissionData
        const currentForm = forms[formKey]

        //create new forms and submission data
        const newForms = {...forms}
        newForms[formKey] = {...currentForm, installStatus}

        const newSubmissionData = {
            ...currentWebsite.submissionData,
            forms: newForms
        }

        const response = await callApi(user, {
            method: 'patch',
            url:`/records/${process.env.GATSBY_WEBSITES_COLLECTION_ID}`,
            data: {
                submissionId: websiteId,
                submissionData: newSubmissionData
            }
        })

        dispatch(updateWebsite({...currentWebsite, submissionData: newSubmissionData}))
        if(onSuccess) {
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }
    
}

//thunk helpers
const getCurrentWebsite = async (user, websiteId, dispatch, getState) => {
    let websites = getState().websites
    let currentWebsite = websites.find(website => website.submissionId === websiteId)
    //fetch websites if state is stale
    if(!currentWebsite) {
        await callThunk(dispatch, fetchWebsites, [user])
        websites = getState().websites
        currentWebsite = websites.find(website => website.submissionId === websiteId)
    }
    return currentWebsite
}