import queryString from "query-string"

//helper functions

export const isBrowser = () => typeof window !== "undefined"

export const isMobile = () => (/Mobi|Android/i.test(navigator.userAgent))

export const isIncognito = async () => {
    //https://mishravikas.com/articles/2019-07/bypassing-anti-incognito-detection-google-chrome.html
    if ('storage' in navigator && 'estimate' in navigator.storage) {
        const {quota} = await navigator.storage.estimate();    
        if(quota < 120000000){
            return true
        } else {
            return false
        }	
    } else {
        //cannot detect
        return null
    }
}

export const isEmpty = s => s.trim() === ""

export const isEmptyObject = o => Object.keys(o).length === 0

export const isWebsite = e => /^[a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z0-9-.]+$/.test(e)

export const getFromQueryString = key => isBrowser() ? queryString.parse(window.location.search)[key] : ""

export const parseQueryString = (key, search) => isBrowser() ? queryString.parse(search)[key] : ""

export const encodeBase64Uri = s => isBrowser() ? encodeURIComponent(window.btoa(s)) : null

export const decodeBase64Uri = s => isBrowser() ? window.atob(decodeURIComponent(s)) : null

export const getFromString = (str, prefix, suffix) => {
    const start = str.indexOf(prefix) + prefix.length;
    return str.slice(start, str.indexOf(suffix, start))
}

export const limitString = (string, limit) =>  string.length > limit ? `${string.slice(0,limit)}...` : string

export const setInputState = (value, setValue, error, setError) => {
    setValue(value)
    setError(error)
}

export const setLocalStorageItem = (key, value) => isBrowser() ? window.localStorage.setItem(key, value) : ''

export const getLocalStorageItem = key => isBrowser() ? window.localStorage.getItem(key) : ''

export const removeLocalStorageItem = key => isBrowser() ? window.localStorage.removeItem(key) : ''

export const callThunk = (dispatch, thunk, params) => new Promise((resolve, reject) => dispatch(thunk(...params, resolve, reject)))

export const handleThunkError = (err, reject) => {
    if(reject) {
        reject(err)
    } else {
        throw err
    }
}