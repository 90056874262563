import { combineReducers } from "@reduxjs/toolkit"
import websites from "./websites"
import hubspotForms from "./hubspotforms"
import userProfile from "./userprofile"
import permissions from "./permissions"
import RESET from "./reset"

const reducer = combineReducers({
    websites,
    hubspotForms,
    userProfile,
    permissions
})

const rootReducer = (state, action) => reducer(action.type === RESET ? undefined : state, action)
export default rootReducer