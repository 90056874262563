import * as Sentry from '@sentry/browser'

export const initializeErrorLogger = (config) => {
    Sentry.init(config)
}

export const logUser = (user) => {
    Sentry.configureScope(scope => user ? scope.setUser({id: user.uid}) : scope.setUser(null))
}

export const logError = (err, callback) => {
    const eventId = Sentry.captureException(err)   
    if(callback) {
        callback(eventId)
    }
}

export const handlePromiseError = (err, reject) => {
    if(reject){
        reject(err)
    } else {
        throw err
    }
}

export const handleEventError = (err, user, callback) => {
    Sentry.withScope(scope => {
        if(user) {
            scope.setUser({id: user.uid})
        }
        logError(err, callback)
    })
}

export const handleBoundaryError = (err, errInfo, callback) => {
    Sentry.withScope((scope) => {
        scope.setExtras(errInfo)
        logError(err, callback)
    })    
}

export const handleUserFeedback = eventId => Sentry.showReportDialog({eventId})
