import React, {useEffect, useContext, createContext } from "react"

import { handleEventError } from "./error"
import { useFirebase } from "./firebase"

const analyticsContext = createContext()

export function AnalyticsProvider({ children }) {
    const firebase = useFirebase()

    useEffect(() => {
        if(firebase) {
            firebase.analytics() 
        }
    },[firebase])

    const EventName = {
        SCREEN_VIEW: 'screen_view',
    }

    const setUserId = (id, options) => {
        try {
            if(firebase) {
                firebase.analytics().setUserId(id, options)
            }
      
        } catch (err) {
            handleEventError(err, false, false)
            throw err
        }
    }

    const logEvent = (eventName, eventParams, options) => {
        try {
            if(firebase) {
                firebase.analytics().logEvent(eventName, eventParams, options)
            }
        } catch (err) {
            handleEventError(err, false, false)
            throw err
        }
    }

    const logScreenView = (screenName, screenLocation) => {
        try {
            if(firebase) {
                firebase.analytics().logEvent(EventName.SCREEN_VIEW, {
                    screen_name: screenName, 
                    screen_location: screenLocation
                })
            }
        } catch (err) {
            handleEventError(err, false, false)
            throw err
        }
    }

    
    const analytics = {
        EventName,
        setUserId, 
        logEvent,
        logScreenView
    }
    
    return <analyticsContext.Provider value={analytics}>{children}</analyticsContext.Provider>
}

// Hook for child components to get the firebase object ...
// ... update when it changes.
export const useAnalytics = () => {
    return useContext(analyticsContext)
}
