import { createSlice } from "@reduxjs/toolkit"

import { createProfile } from "../appstate/userprofile"
import { callApi } from "../services/api"
import { callThunk } from "../services/helper"
import { handlePromiseError } from "../services/error"

const permissions = createSlice({
    name: 'permissions',
    initialState: {},
    reducers: {
        setPermissions(state, action){
            return action.payload
        }
    }
})

export const {
    setPermissions
} = permissions.actions

export default permissions.reducer


// thunk actions
export const fetchPermissions = (user, onSuccess, onError) => async dispatch => {
    try {
        const response = await callApi(user, {
            method:'get',
            url:'/permissions'
        })

        if(response.data.result.isNewUser) {

            //create user profile record
            await callThunk(dispatch, createProfile, [user])
        }
        dispatch(setPermissions({isNewUser: response.data.result.isNewUser, ...response.data.result.permissions}))

        if(onSuccess){
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }  
}

export const createOAuthToken = (user, oAuthProviderKey, token, tokenType, onSuccess, onError) => async dispatch => {
    try {
        const response = await callApi(user,{
            method: 'post',
            url:'/oauthtokens',
            data: {
                token: token,
                tokenType: tokenType,
                oAuthProviderKey: oAuthProviderKey
            }
        })
        dispatch(setPermissions(response.data.result.permissions))
        if(onSuccess){
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }
    
}

export const deleteOAuthToken = (user, oAuthProviderKey, accountKey, refreshToken, onSuccess, onError) => async dispatch => {
    try {
        const response = await callApi(user,{
            method: 'delete',
            url:`/oauthtokens/${refreshToken}`,
            data: {
                accountKey: accountKey,
                oAuthProviderKey: oAuthProviderKey
            }
        })
        dispatch(setPermissions(response.data.result.permissions))
        if(onSuccess){
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }
    
}