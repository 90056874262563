import React from "react"
import { Provider } from "react-redux"

import store from "./src/appstate/store"
import { FirebaseProvider } from "./src/services/firebase"
import { AnalyticsProvider } from "./src/services/analytics"
import { AuthProvider } from "./src/services/auth"
import { ThemeProvider } from "./src/services/theme"
import { initializeErrorLogger } from "./src/services/error"
import GlobalErrorBoundary from "./src/components/errorboundaries/global"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  initializeErrorLogger({
    dsn: process.env.GATSBY_SENTRY_DSN, 
    environment: process.env.GATSBY_SENTRY_ENVIRONMENT,
    debug: process.env.GATSBY_SENTRY_DEBUG === 'true' ? true : false,
    enabled: process.env.GATSBY_SENTRY_ENABLED === 'true' ? true : false
  })
  // Instantiating in `wrapRootElement` handler ensures it will be called only once in browser, when React mounts
  return (
    <GlobalErrorBoundary>
      <Provider store={store}>
        <FirebaseProvider>
          <AnalyticsProvider>
            <AuthProvider>
              <ThemeProvider>
                {element}
              </ThemeProvider>
            </AuthProvider>
          </AnalyticsProvider>
        </FirebaseProvider>
      </Provider>
    </GlobalErrorBoundary> 
  )
}