import { createSlice } from "@reduxjs/toolkit"

import { callApi } from "../services/api"
import { handlePromiseError } from "../services/error"
import * as CONSTANTS from "../services/constant"

const MINIMUM_TOKEN_LIFE = 10000

const hubspotForms = createSlice({
    name: 'hubspotForms',
    initialState: {},
    reducers: {
        setForms(state, action){
            state[action.payload.accountKey] = action.payload.forms
            return state
        }
    }
})

export const {
    setForms
} = hubspotForms.actions

export default hubspotForms.reducer

// thunk actions
export const fetchForms = (user, selectedAccount, refreshCache, onSuccess, onError) => async dispatch => {
    try {
        //get the right hubspot auth token
        const tokenLife = selectedAccount.tokenSet.expires - (new Date()).getTime()
        let token, tokenType
        if(tokenLife > MINIMUM_TOKEN_LIFE) {
            tokenType = CONSTANTS.OAUTH_TOKEN_TYPE_ACCESS
            token = encodeURIComponent(selectedAccount.tokenSet.accessToken)
        } else {
            tokenType = CONSTANTS.OAUTH_TOKEN_TYPE_REFRESH
            token = encodeURIComponent(selectedAccount.tokenSet.refreshToken)
        }

        const response = await callApi(user, {
            method:'get',
            url:'/hubspot/forms',
            params: {
                accountKey: encodeURIComponent(selectedAccount.accountKey),
                tokenType: tokenType,
                token: token,
                refreshCache: refreshCache
            }
        })
        dispatch(setForms({ accountKey: selectedAccount.accountKey, forms: response.data.result.forms }))
        if(onSuccess){
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }
}