import React, { useContext } from "react"
import { ThemeProvider as StyledThemeProvider, ThemeContext, createGlobalStyle } from "styled-components"

import "./theme.css"

const brandColors = {
    darkBlue: `#2858B1`, 
    darkBlue80: `#547BC1`,
    darkBlue60: `#7E9BD1`,
    lightBlue: `#3BC5FF`,
    activeBlue: `#E3F0FD`,
    orange: `#FFD23F`,
    orange40Alpha: `rgba(255, 210, 63, 0.4)`,
    green: `#3BCEAC`,
    red: `#EE4266`,
    black: `#000000`,
    black80Alpha: `rgba(0,0,0,0.8)`,
    black80: `#333333`,
    black60Alpha: `rgba(0,0,0,0.6)`,
    black60: `#666666`,
    black40:`#999999`,
    gray: `#CCCCCC`,
    activeGray: `#EDF2F2`,
    lightGray: `#EFEFEF`,
    white97: `#F8F8F8`,
    white: `#FFFFFF`,        
}

const defaultTheme = {
    colors: {
        primary: brandColors.darkBlue, 
        primaryDisabled: brandColors.darkBlue80,
        primaryExtraDisabled: brandColors.darkBlue60,
        secondary: brandColors.lightBlue,
        accent: brandColors.orange,
        success: brandColors.green,
        error: brandColors.red,
        text: brandColors.black80,
        inverseText: brandColors.white,
        background: brandColors.white,
        alternateBackground: brandColors.white97,
        hoverBackground: brandColors.activeGray,
        inverseHoverBackground: brandColors.gray,
        disabledBackground: brandColors.lightGray,
        disabledText: brandColors.gray,
        placeholderText: brandColors.gray,
        inputBorder: brandColors.black40,
        focusInputBorder: brandColors.black80,
        subtleText: brandColors.black40,
        extraSubtleText: brandColors.gray,
        divider: brandColors.activeGray,
        modalBackground: brandColors.black80Alpha,
        transparentModalBackground: brandColors.black60Alpha,
        modalText: brandColors.lightGray,
        burger: brandColors.gray,
        inverseBurger: brandColors.activeGray,
        footerBackground: brandColors.gray,
        footerText: brandColors.black60,
        footerInverseText: brandColors.white, 
        selectedBackground: brandColors.activeBlue, 
        highlighter: brandColors.orange40Alpha
    },
    breakpoints: {
        small: `479px`,
        mobile: `767px`,
        tablet: `1023px`,
        laptop: `1439px`,
        desktop: `1920px`
    },
    shadow: `0 2px 15px 0 rgba(0, 0, 0, 0.06)`,
    hoverShadow: `0 5px 10px 0 rgba(0, 0, 0, 0.2)`,
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({theme}) => theme.background};
    color: ${({theme}) => theme.colors.text};
  }
`

export const ThemeProvider = ({children}) => (
    <StyledThemeProvider theme={defaultTheme}>
        <>
            <GlobalStyle/>
            {children}
        </>
    </StyledThemeProvider>
)

export const useTheme = () => {
    return useContext(ThemeContext)
}