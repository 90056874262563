import { createSlice } from "@reduxjs/toolkit"

import { callApi } from "../services/api"
import { handlePromiseError } from "../services/error"

const userProfile = createSlice({
    name: 'userProfile',
    initialState: {},
    reducers: {
        setProfile(state, action){
            return action.payload
        }
    }
})

export const {
    setProfile
} = userProfile.actions

export default userProfile.reducer

// thunk actions
export const fetchProfile = (user, onSuccess, onError) => async dispatch => {
    try {
        const response = await callApi(user, {
            method:'get',
            url:`/records/${process.env.GATSBY_PROFILES_COLLECTION_ID}`
        })
        dispatch(setProfile({id: response.data.result[0].submissionId, ...response.data.result[0].submissionData}))
        if(onSuccess){
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }  
}

export const updateProfile = (user, profile, onSuccess, onError) => async dispatch => {
    try {
        const {id, ...profileDetails} = profile
        const response = await callApi(user, {
            method:'patch',
            url:`/records/${process.env.GATSBY_PROFILES_COLLECTION_ID}`,
            data: {
                submissionId: id,
                submissionData: profileDetails
            }
        })
        dispatch(setProfile({...profile}))
        if(onSuccess) {
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }
}

export const createProfile = (user, onSuccess, onError) => async dispatch => {
    try {
        const userInfo = {
            name: user.displayName, 
            email: user.email,
            photoUrl: user.photoURL,
        }
        const response = await callApi(user, {
            method:'post',
            url:`/records`,
            data: {
                collectionId: process.env.GATSBY_PROFILES_COLLECTION_ID,
                submissionData: userInfo
            }
        })
        dispatch(setProfile({id: response.data.result[0].submissionId, ...userInfo}))
        if(onSuccess) {
            onSuccess(response)
        }
    } catch (err) {
        handlePromiseError(err, onError)
    }
}