//global constants

export const WEBSITE_STAGE_PRODUCTION = "production"
export const WEBSITE_STAGE_STAGING = "staging"
export const WEBSITE_STAGE_TESTING = "testing"
export const WEBSITE_STAGE_DEVELOPMENT = "development"
export const WEBSITE_PLATFORM_WEBFLOW = "webflow"
export const WEBSITE_PLATFORM_OTHER = "other" 
export const CONTENT_TYPE_JSON = "application/json"
export const CONFIG_FILE_MAX_AGE = 31536000
export const CONFIG_FILE_CLIENT_CACHE_LIFE = 60
export const CONFIG_FILE_FREE_PLAN_ID = 10
export const FORM_PROVIDER_HUBSPOT = 'hubspot'
export const OAUTH_CACHE_KEY = 'vimkit-oauth-cache'
export const OAUTH_RESULT_KEY = 'vimkit-oauth-result'
export const OAUTH_MODE_POPUP = 'popup'
export const OAUTH_MODE_REDIRECT = 'redirect'
export const OAUTH_TOKEN_TYPE_ACCESS = 'access'
export const OAUTH_TOKEN_TYPE_REFRESH = 'refresh'
export const HUBSPOT_OAUTH_AUTH_CODE_KEY = 'code'
export const HUBSPOT_OAUTH_ERROR_CODE_KEY = 'error'
export const HUBSPOT_OAUTH_ERROR_MESSAGE_KEY = 'error_description'
export const HUBSPOT_OAUTH_PROVIDER_KEY = 'hubspot'
export const HUBSPOT_FORM_PROVIDER_KEY = 'hubspot' // this must match action type on vimkit core sequences
export const USER_STATUS_CACHE_KEY = `vimkit-loggedin`
export const USER_STATUS_LOGGED_IN = `true`
export const USER_PERSONA_CLIENTS = `clients`
export const USER_PERSONA_COMPANY = `company`
export const USER_PERSONA_MYSELF = `myself`
export const USER_PERSONA_NONE = `none`
export const USER_INTENT_TRY = `try`
export const USER_INTENT_EXPLORE = `explore`
export const USER_INTENT_SKIP = `skip`
export const INTEGRATION_PROVIDER_KEY = `provider`