import React, { Component } from "react"
import styled from "styled-components"
import { handleBoundaryError } from "../../services/error"
//import { handleUserFeedback } from "../../services/error"
import errorDoodle from "../../images/errordoodle.svg"
import logo from "../../images/logo.svg"

const ErrorTitle = styled.h3`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 0px;
    @media (max-width: 1023px) {
        font-size: 20px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
    }
`

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
`

const ErrorVector = styled.img`
    padding: 3%;
    max-width: ${({maxWidth}) => maxWidth};

`

class GlobalErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            eventId: null,
            hasError: false 
        }
    }

    static getDerivedStateFromError() {
      return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        handleBoundaryError(error, errorInfo, eventId => this.setState({eventId}))
    }

    render() {
        if (this.state.hasError) {
            //render fallback UI
            return (
                <ErrorWrapper>
                    <ErrorVector src={logo} maxWidth="102px"/> 
                    <ErrorTitle>Oops! Something's not right.</ErrorTitle>
                    <p>Try refreshing the page or reach out to customer support.</p>
                    <ErrorVector src={errorDoodle} maxWidth="250px" />
                    {/*
                        <button onClick={() => handleUserFeedback(this.props.eventId)}>Report feedback</button>
                    */}
                </ErrorWrapper>
            )
            
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default GlobalErrorBoundary